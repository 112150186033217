import { PURGE } from "redux-persist";

const initialState = {
  resources: [],
  therapistResources: [],
  patientResources: [],
  patientPublicResources: [],
  groupResources: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PUBLIC_LIBRARY":
      return {
        ...state,
        resources: [...payload],
      };
    case "FETCH_PATIENT_LIBRARY":
      return {
        ...state,
        patientResources: [...payload],
      };
    case "FETCH_THERAPIST_LIBRARY":
      return {
        ...state,
        therapistResources: [...payload],
      };
    case "FETCH_GROUP_LIBRARY":
      return {
        ...state,
        groupResources: [...payload],
      };
    case "ADD_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: [...state.therapistResources, payload],
      };
    case "ADD_GROUP_LIBRARY_RESOURCES":
      return {
        ...state,
        groupResources: [...state.groupResources, ...payload],
      };
    case "UPDATE_THERAPIST_LIBRARY_RESOURCES":
      const updatedResources = [...state.therapistResources];
      payload.map((item) => {
        const indexToUpdate = state.therapistResources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate >= 0) {
          updatedResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        therapistResources: updatedResources,
      };
    case "UPDATE_GROUP_LIBRARY_RESOURCE":
      const updatedGroupResources = [...state.groupResources];
      payload.map((item) => {
        const indexToUpdate = state.groupResources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate > 0) {
          updatedGroupResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        groupResources: updatedGroupResources,
      };
    case "DELETE_THERAPIST_LIBRARY_RESOURCE":
      return {
        ...state,
        therapistResources: state.therapistResources.filter(
          (resource) => resource.uuid !== payload
        ),
      };
    case "DELETE_GROUP_LIBRARY_RESOURCES":
      const filteredGroupResources = state.groupResources.filter(
        (resource) => !payload.includes(resource.uuid)
      );
      return {
        ...state,
        groupResources: filteredGroupResources,
      };
    case "FETCH_PUBLIC_PATIENT_LIBRARY":
      return {
        ...state,
        patientPublicResources: [...payload],
      };
    case "ADD_PUBLIC_PATIENT_LIBRARY":
      return {
        ...state,
        patientPublicResources: [...state.patientPublicResources, ...payload],
      };
    case "UPDATE_PUBLIC_LIBRARY_RESOURCES":
      const publicResources = [...state.resources];
      payload.map((item) => {
        const indexToUpdate = state.resources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate >= 0) {
          publicResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        resources: publicResources,
      };
    case "DELETE_PUBLIC_PATIENT_LIBRARY_RESOURCE":
      return {
        ...state,
        patientPublicResources: state.patientPublicResources.filter(
          (resource) => resource.uuid !== payload.uuid
        ),
      };
    case "UPDATE_PUBLIC_PATIENT_LIBRARY":
      const updatedPublicPatientResources = [...state.patientPublicResources];
      payload.map((item) => {
        const indexToUpdate = state.patientPublicResources.findIndex(
          (resource) => resource.uuid === item.uuid
        );
        if (indexToUpdate >= 0) {
          updatedPublicPatientResources.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        patientPublicResources: updatedPublicPatientResources,
      };
    case "DELETE_PUBLIC_LIBRARY_RESOURCE":
      return {
        ...state,
        resources: state.resources.filter(
          (resource) => resource.uuid !== payload
        ),
      };

    case PURGE:
      return initialState;
    default:
      return state;
  }
};
