import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  useFetchAssignments,
  useFetchMoodScales,
  useFetchPatients,
  useFetchSubmissions,
  useFetchTherapistProfile,
  useFetchTherapists,
  useLogout,
} from "utils/hooks";
import { setAuthenticatedUser } from "store/actions/auth";
import { User } from "types";

const useVerifyCode = () => {
  const dispatch = useDispatch();
  const { fetchAllPatients } = useFetchPatients();
  const { fetchAllTherapists } = useFetchTherapists();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { fetchTherapistProfile } = useFetchTherapistProfile();
  const { fetchAllAssignments } = useFetchAssignments();
  const { fetchAllMoodScales } = useFetchMoodScales();
  const { logOut } = useLogout();

  const verifyCode = async (args: { user: User | null; code: string }) => {
    const { user, code } = args;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_REST_API_URL}/2fa/verify-code`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, code }),
        }
      );

      const resData = await response.json();

      if (response.ok) {
        ReactGA.event("login_success", {
          user_id: user?.id,
          user_type: user?.type,
          user_verified: user?.verified,
        });
        const res = dispatch(setAuthenticatedUser(user));

        // fetching some data on login
        if (res.payload.type === "therapist") {
          fetchAllPatients();
          fetchAllSubmissions();
          fetchTherapistProfile();
          fetchAllAssignments();
          fetchAllMoodScales();
        }

        if (res.payload.type === "patient") {
          fetchAllTherapists();
          fetchAllAssignments();
        }

        toast("Great to see you!");
      }

      if (!response.ok) {
        if (resData.message.includes("BRKN_6001")) {
          toast.warn("For security purposes please log in again.");
          logOut();
        } else {
          toast.error(resData.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    verifyCode,
  };
};

export default useVerifyCode;
