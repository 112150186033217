import { useState } from "react";
import ReactGA from "react-ga4";
import {
  updateLibraryResources,
  updatePublicLibraryResourcesAction,
} from "store/actions/library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser, useGetTherapistLibrary } from "utils/hooks";
import { formatInput } from "utils/helpers";
import { formattedSharedWith } from "./libraryUtils";
import { LibraryResource } from "types";

interface Props {
  resource: any;
  selectedPatients: any;
}

const useUpdateLibraryResource = ({ resource, selectedPatients }: Props) => {
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const { user } = useCurrentUser();
  const { therapistResources } = useGetTherapistLibrary({});

  const formatResource = (arg: LibraryResource) =>
    `{
      uuid: "${arg.uuid ? arg.uuid : resource.uuid}",
      resourceUrl: "${formatInput(arg.resourceUrl)}",
      title: "${formatInput(arg.title)}",
      description: "${formatInput(arg.description)}",
      imageUrl: "${formatInput(arg.imageUrl)}",
      sharedWith: [${formattedSharedWith(selectedPatients)}]
    }`;

  const submitUpdateLibraryResource = (arg: LibraryResource) => {
    if (!arg) {
      return null;
    }

    setLoading(true);

    const filteredResources = therapistResources.filter(
      (therapistResource: LibraryResource) =>
        therapistResource.uuid !== resource.uuid
    );

    const existingUrl = filteredResources.find(
      (therapistResource: LibraryResource) =>
        therapistResource.resourceUrl === resource.resourceUrl
    );

    if (existingUrl) {
      toast.warn(`A link with this url already exists in your library :)`);
      return null;
    }

    return new Promise((resolve: any) => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
              mutation {
                updateLibraryResource(
                  resource: ${formatResource(arg)}, 
                ) {
                  resourceUrl
                  title
                  description
                  imageUrl
                  uuid
                  addedBy
                  updatedAt
                  sharedWith {
                    label
                    value
                  }
                }
              }
            `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            organizationId: user?.activeOrganizationId || "NA",
            permissionKey: "update_links",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                setLoading(false);
                logOut();
              } else {
                toast.error(
                  `${error.errors[0].message || "Hmm, something went wrong."}`
                );
                setLoading(false);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
              setLoading(false);
              resolve();
              return true;
            } else {
              toast("Resource Updated");
              setIsUpdateSuccessful(true);
              resolve();
              ReactGA.event("library_resource_added_success", {
                user_id: user?.id,
                group_id: resData.data.updateLibraryResource.groupId,
              });
              dispatch(
                updateLibraryResources([resData.data.updateLibraryResource])
              );
              dispatch(
                updatePublicLibraryResourcesAction([
                  resData.data.updateLibraryResource,
                ])
              );
              return setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    submitUpdateLibraryResource,
    isUpdateSuccessful,
    loading,
  };
};

export default useUpdateLibraryResource;
