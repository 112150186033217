import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useFetchLibrary, useCurrentUser } from "utils/hooks";
import { LibraryResource } from "types";

interface UseGetTherapistLibraryProps {
  groupId?: string;
}

const useGetGroupLibrary = ({ groupId }: UseGetTherapistLibraryProps) => {
  const [groupResByTherapist, setGroupResByTherapist] = useState<
    Array<LibraryResource> | []
  >([]);
  const [groupResPublic, setGroupResPublic] = useState<
    Array<LibraryResource> | []
  >([]);
  const { fetchGroupLibrary } = useFetchLibrary();
  const { user } = useCurrentUser();
  const templateMasterId = process.env.REACT_APP_TEMPLATE_MASTER_ID;

  const resources = useSelector(
    (state: RootStateOrAny) => state.resources && state.resources
  );

  const allGroupResources = resources?.groupResources;

  useEffect(() => {
    if (groupId) {
      fetchGroupLibrary(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const groupResourcesByTherapist = allGroupResources.filter(
      (r: LibraryResource) => r.addedBy === user?.id
    );
    const groupResourcesByTempMaster = allGroupResources.filter(
      (r: LibraryResource) => r.addedBy === templateMasterId
    );

    setGroupResByTherapist(groupResourcesByTherapist);
    setGroupResPublic(groupResourcesByTempMaster);
  }, [allGroupResources, user, templateMasterId]);

  return {
    groupResources: allGroupResources || [],
    groupResByTherapist,
    groupResPublic,
  };
};

export default useGetGroupLibrary;
