import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LibraryResource, Collection } from "types";
import { useCurrentUser, useGetPatients, useGetGroups } from "utils/hooks";
import { Button, Divider } from "components";
import AddLibraryResourceForm from "pages/AppPages/Library/components/AddLibraryResourceForm";
import AddResourcesForPatient from "pages/AppPages/Library/components/AddResourcesForPatient";
import CollectionsForSelect from "./components/CollectionsForSelect";
import LinksForSelect from "./components/LinksForSelect";
import { BRAND_NAME } from "utils/constants";

interface AddLinksBodyProps {
  therapistLinks: LibraryResource[] | [];
  therapistCollections: Collection[] | [];
  publicLinks: LibraryResource[] | [];
  selectedResources: any;
  setSelectedResources: any;
  selectedCollections?: Collection[] | [];
  setSelectedCollections?: any;
  handleCloseSidePanel: () => void;
  currentResource: LibraryResource | null;
  setShowResourceForm: any;
}

type SelectResourceProps = {
  resource?: LibraryResource;
  collection?: Collection;
  e: any;
};

const AddLinksBody = ({
  therapistLinks,
  therapistCollections,
  publicLinks,
  selectedResources,
  setSelectedResources,
  selectedCollections,
  setSelectedCollections,
  handleCloseSidePanel,
  currentResource,
  setShowResourceForm,
}: AddLinksBodyProps) => {
  const { user } = useCurrentUser();
  const { uuid } = useParams();
  const { patient } = useGetPatients({ slug: uuid });
  const { specificGroup: group } = useGetGroups({ slug: uuid });
  const [showForm, setShowForm] = useState(false);
  const topRef = useRef<HTMLDivElement | null>(null);

  const onlyTherapistCollections = useMemo(
    () =>
      therapistCollections.filter(
        (collection: Collection) => collection.userId === user?.id
      ),
    [therapistCollections, user]
  );

  const templateMasterCollections = useMemo(
    () =>
      therapistCollections.filter(
        (collection: Collection) =>
          collection.userId === process.env.REACT_APP_TEMPLATE_MASTER_ID
      ),
    [therapistCollections]
  );

  useEffect(() => {
    if (showForm) {
      topRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showForm]);

  const handleSelectResource = ({
    resource,
    collection,
    e,
  }: SelectResourceProps) => {
    e.preventDefault();
    let isSelected;
    if (resource) {
      isSelected = selectedResources.find(
        (item: LibraryResource) => item.uuid === resource.uuid
      );
      if (isSelected) {
        setSelectedResources(
          [...selectedResources].filter((item) => item.uuid !== resource.uuid)
        );
      } else {
        setSelectedResources([...selectedResources, resource]);
      }
    } else if (collection) {
      if (!selectedCollections) return;
      isSelected = selectedCollections.find((c) => c.uuid === collection.uuid);
      if (isSelected) {
        setSelectedCollections(
          [...selectedCollections].filter((c) => c.uuid !== collection.uuid)
        );
      } else {
        setSelectedCollections([...selectedCollections, collection]);
      }
    }
  };

  return (
    <>
      <div className="bottom-modal_body flex space-between">
        <div className="w-100">
          <div className="flex bottom-modal_library">
            {onlyTherapistCollections.length !== 0 && (
              <CollectionsForSelect
                collections={onlyTherapistCollections}
                selectedCollections={selectedCollections}
                handleSelectResource={handleSelectResource}
              />
            )}
            {therapistLinks.length !== 0 && (
              <LinksForSelect
                links={therapistLinks}
                selectedResources={selectedResources}
                handleSelectResource={handleSelectResource}
                user={user}
              />
            )}
          </div>
          {(templateMasterCollections.length !== 0 ||
            publicLinks.length !== 0) && (
            <>
              <h3 className="brand_name">From {BRAND_NAME}</h3>
              <div className="divider_line w-100">
                <Divider />
              </div>
            </>
          )}
          <div className="flex bottom-modal_library">
            {templateMasterCollections.length !== 0 && (
              <CollectionsForSelect
                collections={templateMasterCollections}
                selectedCollections={selectedCollections}
                handleSelectResource={handleSelectResource}
              />
            )}
            {publicLinks.length !== 0 && (
              <LinksForSelect
                links={publicLinks}
                selectedResources={selectedResources}
                handleSelectResource={handleSelectResource}
                user={user}
              />
            )}
          </div>
        </div>

        <div
          className={`bottom-modal_form ${
            showForm && "bottom-modal_form__visible"
          }`}
          ref={topRef}
        >
          {selectedResources?.length === 0 &&
          selectedCollections?.length === 0 ? (
            <AddLibraryResourceForm
              onSuccess={handleCloseSidePanel}
              resource={currentResource}
              showPatients={!currentResource}
              defaultSharedWith={patient}
              groupId={group?.uuid}
              title={"Add New Link"}
              shareInput={false}
              submitButtonText="Add Link"
            />
          ) : (
            <AddResourcesForPatient
              selectedResources={selectedResources}
              selectedCollections={selectedCollections}
              patient={patient}
              group={group}
              setSelectedResources={setSelectedResources}
              setSelectedCollections={setSelectedCollections}
              resourceType={"link"}
              setShowResourceForm={setShowResourceForm}
            />
          )}
          <Button
            name="bottom-modal_hide-form"
            value={""}
            icon="CloseCross"
            extraClass="bottom-modal_hide-form  bottom-modal_button-close transparent"
            onClick={() => setShowForm(false)}
          />
        </div>
      </div>

      {!showForm && (
        <Button
          name="show-form-button"
          value={
            selectedResources.length === 0 ? "Add New Link" : "Selected Links"
          }
          extraClass="bottom-modal_showForm-button"
          onClick={() => {
            setShowForm(true);
          }}
        />
      )}
    </>
  );
};

export default AddLinksBody;
