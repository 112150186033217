import React, { useState, useEffect } from "react";
import "./CheckboxGrid.styles.scss";
import Checkbox from "../Checkbox/Checkbox";
import { Field, CheckboxOption, CheckboxRow } from "types";

interface CheckboxGridProps extends Field {
  label: string;
  description?: string;
  checkboxes?: CheckboxOption[]; // Required, as CheckboxRows reference these
  checkboxRows?: CheckboxRow[]; // New prop for rows with selections
  onCheckboxRowsChange?: (rowsWithOptions: string) => void; // Callback to send updated rows with options
  allowMulti?: boolean; // Allow multiple checkbox selections per row
  verticalStack?: boolean; // Orientation for the checkbox layout
}

const CheckboxGrid: React.FC<CheckboxGridProps> = ({
  label,
  description,
  checkboxes = [],
  checkboxRows = [],
  onCheckboxRowsChange,
  allowMulti = false,
  verticalStack = true, // Default to vertical stack
}) => {
  // Local state for optimistic UI
  const [localCheckboxRows, setLocalCheckboxRows] = useState<
    CheckboxRow[] | undefined
  >(checkboxRows);

  // Sync local state with external prop changes
  useEffect(() => {
    setLocalCheckboxRows(checkboxRows);
  }, [checkboxRows]);

  // Handle checkbox selection for a specific row
  const handleCheckboxChange = (
    rowId: string,
    checkboxId: string | undefined
  ) => {
    if (!checkboxId) return; // Skip if checkboxId is undefined

    const updatedRows = localCheckboxRows?.map((row) => {
      if (row.id === rowId) {
        const isSelected = row.selectedOptionIds.includes(checkboxId);

        const updatedSelectedOptionIds = allowMulti
          ? isSelected
            ? row.selectedOptionIds.filter((id) => id !== checkboxId) // Remove if already selected
            : [...row.selectedOptionIds, checkboxId] // Add if not selected
          : isSelected
          ? [] // If already selected, deselect it when `allowMulti` is false
          : [checkboxId]; // Otherwise, only keep the current selection when `allowMulti` is false

        return { ...row, selectedOptionIds: updatedSelectedOptionIds };
      }
      return row;
    });

    // Update local state
    setLocalCheckboxRows(updatedRows);

    // Notify parent with serialized data if a callback is provided
    if (onCheckboxRowsChange) {
      const serializedData = JSON.stringify({
        rows: updatedRows || [],
        options: checkboxes,
      });
      onCheckboxRowsChange(serializedData);
    }
  };

  return (
    <div className="checkbox-grid">
      <div className="checkbox-grid__label">{label}</div>
      <div className="checkbox-grid__description">{description}</div>
      {localCheckboxRows?.map((row) => (
        <div key={row.id} className="checkbox-grid__row">
          <div className="checkbox-grid__row-label">{row.label}</div>
          {row.description && (
            <div className="checkbox-grid__row-description">
              {row.description}
            </div>
          )}
          <div
            className={`checkbox-grid__container ${
              verticalStack ? "vertical" : "horizontal"
            }`}
          >
            {checkboxes.map((checkbox) => {
              if (!checkbox.id) return null; // Skip rendering if id is undefined

              const isChecked = row.selectedOptionIds.includes(checkbox.id);

              return (
                <div
                  className={`checkbox-grid__item ${
                    verticalStack ? "vertical" : ""
                  }`}
                  key={checkbox.id}
                >
                  <Checkbox
                    round={!allowMulti}
                    large
                    id={`${row.id}-${checkbox.id}`}
                    label={checkbox.label}
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(row.id, checkbox.id)}
                    stackLabelSide={verticalStack}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGrid;
