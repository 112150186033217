import { Collection, LibraryResource } from "types";

export const filterTherapistResources = (
  array: any,
  patientId: string,
  collections: Array<Collection>
) => {
  // Filters therapist resources (already shared with this patient resources and resources included in collections will be excluded from the returning array)
  if (array.length !== 0) {
    const filteredByPatientId = array.filter((element: any) => {
      const hasPatient = element.sharedWith?.find(
        (item: { value: any }) => item.value === patientId
      );
      return !hasPatient;
    });
    const filteredByPatientIdIds = filteredByPatientId.map(
      (el: any) => el.uuid
    );

    const idsInCollections: Array<string> = [];

    collections.map((collection: Collection) => {
      collection.collectionResources.map((resource) => {
        if (filteredByPatientIdIds.includes(resource.uuid)) {
          idsInCollections.push(resource.uuid);
        }
      });
    });

    return filteredByPatientId.filter(
      (element: any) => !idsInCollections.includes(element.uuid)
    );
  } else {
    return [];
  }
};

export const filterPublicResources = (
  publicResources: any,
  patientPublicResources: any
) => {
  if (publicResources.length === 0) return [];
  return publicResources.filter((resource: any) => {
    const sharedToPatient = patientPublicResources.find(
      (item: any) => item.uuid === resource.uuid
    );
    return !sharedToPatient;
  });
};

export const combineLinksAndFiles = (linkArr: any[], fileArr: any[]) => {
  return [
    ...linkArr
      .filter((r) => r)
      .map((resource) => ({
        ...resource,
        type: "resource",
      })),
    ...fileArr.filter((r) => r).map((file) => ({ ...file, type: "file" })),
  ].sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
};

export const filterFilesByPatientId = (array: any[], string: any) => {
  const filteredArray = array.map((element: { sharedWith: any[] }) => {
    const hasPatient = element.sharedWith?.find(
      (item: { value: any }) => item.value === string
    );
    if (hasPatient) {
      return element;
    }
    return false;
  });

  return filteredArray;
};

export const filterCollections = (
  collections: Array<Collection>,
  patientId: string
) => {
  return collections.filter((collection) => {
    return !collection.sharedWith.includes(patientId);
  });
};

export const getCollectionImage = (coll: Collection) => {
  const resource = coll.collectionResources.find(
    (resource) => resource.imageUrl
  );
  return resource ? resource.imageUrl : "";
};

export const getUniqueResources = (
  firstArray: LibraryResource[],
  secondArray: LibraryResource[]
) => {
  return firstArray.filter(
    (resource) => !secondArray.find((el) => el.uuid === resource.uuid)
  );
};

export const createTitleForModal = (patient: any, type: any) => {
  return `Add ${type} for ${
    patient?.firstName[0]?.toUpperCase() +
    patient?.firstName.slice(1) +
    " " +
    patient?.lastName[0]?.toUpperCase() +
    patient?.lastName.slice(1)
  }`;
};
