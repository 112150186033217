import React from "react";
import "./CheckboxGridResponse.styles.scss";
import { useCurrentUser } from "utils/hooks";
import Icon from "components/utility/Icon";

interface CheckboxRow {
  id: string;
  label: string;
  description?: string;
  order: number;
  selectedOptionIds: string[];
}

interface CheckboxOption {
  id: string;
  label: string;
  score?: number;
}

interface CheckboxGridResponseProps {
  choices: string; // Expecting a stringified JSON with rows and options
}

const CheckboxGridResponse: React.FC<CheckboxGridResponseProps> = ({
  choices,
}) => {
  const { isPatient } = useCurrentUser();

  // Parse the stringified choices prop into rows and options
  const parsedData = JSON.parse(choices) as {
    rows: CheckboxRow[];
    options: CheckboxOption[];
  };

  const { rows, options } = parsedData;

  return (
    <div className="checkbox-grid-response">
      {rows.map((row) => {
        // Get the selected options for this row
        const selectedOptions = row.selectedOptionIds
          .map((id) => options.find((option) => option.id === id))
          .filter((option): option is CheckboxOption => option !== undefined);

        // Combine labels of selected options
        const selectedLabels = selectedOptions
          .map((option) => option.label)
          .join(", ");

        return (
          <div
            key={row.id}
            className={`checkbox-grid-response__row ${
              !isPatient ? "horizontal" : ""
            }`}
          >
            <div className="checkbox-grid-response__row-label">{row.label}</div>
            {row.description && (
              <div className="checkbox-grid-response__row-description">
                {row.description}
              </div>
            )}
            <div className="checkbox-grid-response__choices-wrapper">
              <div className="checkbox-grid-response__choices">
                {options.map((option) => {
                  const isChecked = row.selectedOptionIds.includes(option.id);
                  return (
                    <div
                      key={option.id}
                      className={`checkbox-grid-response__circle ${
                        isChecked
                          ? "checkbox-grid-response__circle--selected"
                          : ""
                      }`}
                    >
                      {isPatient ? (
                        <Icon src={"Check"} />
                      ) : (
                        <>{isChecked ? option.score ?? "" : ""}</>
                      )}
                    </div>
                  );
                })}
              </div>
              {selectedOptions.length > 0 && (
                <p className="checkbox-grid-response__selected-labels">
                  {selectedLabels}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxGridResponse;
