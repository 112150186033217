import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addPrivateTemplateAction } from "store/actions/templates";
import { slugGenerator, smallIdGenerator } from "utils/helpers";
import {
  useCreateTemplate,
  useDeleteTemplate,
  useGetTemplates,
  useUpdateTemplate,
} from "utils/hooks";
import { v4 as uuid } from "uuid";
import {
  Field,
  FieldType,
  Icon,
  LibraryMoodScale,
  Section,
  Tag,
  Template,
} from "types";

type validationErrors = {
  [key: string]: string;
};

const useAssignmentBuilderContext = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { slug, section: sectionSlug } = useParams();

  const moodScales = useSelector<{
    moodScales: LibraryMoodScale[];
  }>((state) => state.moodScales && state.moodScales) as LibraryMoodScale[];

  // Check to see if draft version of current assignment exists
  const { builderDrafts, allOwnedTemplates } = useGetTemplates({});

  const localDraftFromAssignmentTemplate = allOwnedTemplates.find(
    (t) => t.uuid === slug || t.slug === slug
  );

  const [draft, setDraft] = useState<Template>({} as Template);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { submitCreateTemplate, isSubmitSuccessful } = useCreateTemplate();
  const { submitUpdateTemplate, isSubmitSuccessful: isUpdateSuccessful } =
    useUpdateTemplate();
  const { submitDeleteTemplate } = useDeleteTemplate();

  const handleHasChanges = () => {
    setHasChanges(true);
  };

  const sections: Array<Section> = draft.content;

  // Handle the current section
  const getSectionFromUrl = useCallback(() => {
    if (sectionSlug && sectionSlug !== "undefined") {
      return sections?.find((section) => section.slug === sectionSlug) || null;
    }
    return null;
  }, [sectionSlug, sections]);

  const [section, setSection] = useState<Section | null>(
    getSectionFromUrl() || (sections && sections[0])
  );

  const [fields, setFields] = useState<Array<Field>>([]);

  const [validationErrors, setValidationErrors] = useState<validationErrors>(
    {}
  );

  const validateFields = () => {
    const newErrors: validationErrors = {};
    fields.forEach((field, index) => {
      if (field.type === "FILE_ATTACHMENT" && !field.fileId) {
        newErrors[`FILE_ATTACHMENT${index}`] = "No file selected";
      }

      if (field.type === "IMAGE" && !field.fileUrl) {
        newErrors[`IMAGE${index}`] = "No image selected";
      }

      if (field.type === "VIDEO_EMBED" && !field.videoURL) {
        newErrors[`VIDEO_EMBED${index}`] = "No video URL entered";
      }
    });

    return newErrors;
  };

  const handleSetSection = useCallback(
    (section: Section | null | undefined) => {
      if (!section) {
        return;
      }
      setSection(section);
      setFields(section?.fields || []);
      history(`/builder/${slug}/${section?.slug}`);
    },
    [history, slug]
  );

  const handleSetDraft = useCallback(
    (slug: string | null) => {
      const foundDraft =
        localDraftFromAssignmentTemplate ||
        builderDrafts?.find(
          (builderDraft: Template) => builderDraft.slug === slug
        );

      if (foundDraft) {
        setDraft(foundDraft);
        if (slug && !sectionSlug) {
          const firstSection = foundDraft?.sections
            ? sections[0]
            : foundDraft?.content[0];
          handleSetSection(firstSection);
        }
      }
    },
    [
      localDraftFromAssignmentTemplate,
      builderDrafts,
      handleSetSection,
      sectionSlug,
      sections,
    ]
  );

  useEffect(() => {
    if (slug) {
      handleSetDraft(slug);
    } else {
      handleSetDraft(null);
    }
  }, [handleSetDraft, slug]);

  useEffect(() => {
    if (slug && sectionSlug !== "undefined" && sectionSlug !== section?.slug) {
      const newSection = getSectionFromUrl() || (sections && sections[0]);
      setSection(newSection);
      setFields(newSection?.fields || []);
    }
  }, [getSectionFromUrl, section, sectionSlug, slug, sections]);

  const handleSaveDraft = async () => {
    if (!draft) {
      const slug = await slugGenerator({ string: "cstmasngmnt" });
      const draftUUID = uuid();
      const newDraft: Template = {
        uuid: draftUUID,
        title: `Untitled Assignment ${
          builderDrafts?.length > 0 ? builderDrafts?.length + 1 : ""
        }`,
        description: "",
        instructions: "",
        status: "DRAFT",
        category: "WORKSHEET",
        slug: slug,
        icon: {
          name: "Draft",
          src: "DraftIcon",
        },
        color: "#89CFF0",
        content: [
          {
            uuid: `${draftUUID}-page-1`,
            name: "Page 1",
            assignmentId: draftUUID,
            slug: `${slug}-page-1`,
            createdAt: new Date().getTime(),
            type: "NEW_PAGE_1",
            label: "Page 1",
            status: "VISIBLE",
            fields: [
              {
                uuid: uuid(),
                type: "DATE",
                label: "Date",
                status: "VISIBLE",
                order: 1,
                name: "date",
                sectionId: `${draftUUID}-page-1`,
                required: false,
                hint: "",
                min: 0,
                max: 0,
                options: [],
              },
            ],
            theme: "1",
            order: 1,
            description: "",
          },
        ],
      };
      submitCreateTemplate(newDraft);
      dispatch(addPrivateTemplateAction(newDraft));
      history(`/builder/${slug}/${slug}-page-1`);
      handleSetDraft(slug);
      handleSetSection(newDraft.content?.[0]);
      setFields(newDraft.content?.[0].fields || []);
    }
  };

  const handleDeleteDraft = (draft: Template) => {
    submitDeleteTemplate(draft);
    handleSetDraft(null);
  };

  const addSection = () => {
    const existingNames = new Set(sections.map((section) => section.name));
    let nextPageNumber = sections.length + 1;

    while (existingNames.has(`Page ${nextPageNumber}`)) {
      nextPageNumber++;
    }

    const newSection: Section = {
      uuid: uuid(),
      name: `Page ${nextPageNumber}`,
      assignmentId: draft?.uuid,
      slug: `${draft?.slug}-page-${nextPageNumber}`,
      createdAt: new Date().getTime(),
      type: `NEW_PAGE_${nextPageNumber}`,
      label: `Page ${nextPageNumber}`,
      status: "VISIBLE",
      fields: [],
      theme: "1",
      order: nextPageNumber,
      description: "New page of your assignment",
    };

    const updatedDraft = { ...draft };

    updatedDraft.content = [...sections, newSection];

    handleHasChanges();
    handleSetSection(newSection);
    setFields(newSection.fields);
    submitUpdateTemplate(updatedDraft);
  };

  const editSection = (updatedSection: Section) => {
    const updatedDraft = {
      ...draft,
      content: sections.map((section) => {
        if (section.isDefaultStart) {
          if (section.uuid !== updatedSection.uuid) {
            section.isDefaultStart = false;
          }
        }
        if (section.uuid === updatedSection.uuid) {
          return updatedSection;
        }

        return section;
      }),
    };

    handleHasChanges();
    handleSetSection(updatedSection);
    setFields(updatedSection.fields);
    submitUpdateTemplate(updatedDraft);
  };

  const handleUpdateDraftSettings = ({
    title,
    description,
    instructions,
    color,
    icon,
    tags,
    category,
  }: {
    title: string;
    description: string;
    instructions: string;
    color: string;
    icon: Icon;
    tags: Array<Tag>;
    category: string;
  }) => {
    // Manage date field based on category
    const updatedFields = [...fields];
    const dateFieldIndex = updatedFields.findIndex(
      (field) => field.type === "DATE"
    );

    if (category === "INFOSHEET" && dateFieldIndex !== -1) {
      // Remove the date field for INFOSHEET
      updatedFields.splice(dateFieldIndex, 1);
    } else if (category === "WORKSHEET" && dateFieldIndex === -1) {
      // Add the date field for WORKSHEET if it doesn't exist
      const dateField: Field = {
        uuid: uuid(),
        type: "DATE",
        label: "Date",
        status: "VISIBLE",
        order: updatedFields.length + 1,
        name: "date",
        sectionId: section?.uuid || uuid(),
        required: false,
        hint: "",
        min: 0,
        max: 0,
        options: [],
      };
      updatedFields.push(dateField);
    }

    // Update the draft with the new fields
    const updatedDraft: Template = {
      ...(draft as Template),
      title,
      description,
      instructions,
      color,
      icon,
      tags,
      category: category
        ? (category as "WORKSHEET" | "INFOSHEET" | "ASSESSMENT")
        : draft.category,
      content: sections.map((sec) =>
        sec.uuid === section?.uuid ? { ...sec, fields: updatedFields } : sec
      ),
    };

    handleHasChanges();
    setFields(updatedFields); // Update the fields state
    submitUpdateTemplate(updatedDraft);
  };

  const deleteSection = (sectionToDelete: Section) => {
    const updatedDraft: Template = { ...(draft as Template) };

    const remainingSections = sections.filter(
      (s) => s.uuid !== sectionToDelete.uuid
    );

    remainingSections.forEach((section, index) => {
      section.name = `Page ${index + 1}`;
      section.slug = `${draft?.slug}-page-${index + 1}`;
      section.order = index + 1;
    });

    updatedDraft.content = remainingSections;

    handleHasChanges();
    submitUpdateTemplate(updatedDraft);

    const nextSection: Section | null =
      remainingSections.length > 0 ? remainingSections[0] : null;
    handleSetSection(nextSection);
  };

  const addField = async (field: FieldType, values?: Partial<Field>) => {
    const getSpecificFieldProps = async () => {
      if (field.type === "SCALE") {
        return {
          min: 0,
          max: 10,
          defaultValue: 5,
          minLabel: "",
          maxLabel: "",
        };
      }
      if (field.type === "FILE_ATTACHMENT") {
        return {
          required: false,
        };
      }
      if (field.type === "SELECT_FIELD") {
        return {
          options: [
            {
              label: "",
              value: "",
            },
            {
              label: "",
              value: "",
            },
          ],
        };
      }
      if (field.type === "CHECKBOX_GRID") {
        return {
          checkboxOptions: [
            {
              label: "Option 1",
              score: 0,
              id: await smallIdGenerator(),
            },
            {
              label: "Option 2",
              score: 1,
              id: await smallIdGenerator(),
            },
            {
              label: "Option 3",
              score: 2,
              id: await smallIdGenerator(),
            },
            {
              label: "Option 4",
              score: 3,
              id: await smallIdGenerator(),
            },
          ],
          checkboxRows: [
            {
              id: await smallIdGenerator(),
              label: "Row 1",
              description: "",
              order: 0,
              selectedOptionIds: [], // No options selected by default
            },
          ],
        };
      }
      if (field.type === "MULTI_RESPONSE") {
        return {
          multiResponseOptions: [
            {
              placeholder: "",
            },
            {
              placeholder: "",
            },
          ],
        };
      }
      if (field.type === "CUSTOM_MOOD_SCALE") {
        return {
          moodScale: {
            label: "",
            description: "",
            emojis: [
              { emoji: "☹️", label: "I'm sad" },
              { emoji: "🤩", label: "I'm Amazing!" },
            ],
          },
        };
      }
      if (field.type === "INFO_BOX") {
        return {
          colorTheme: "#083D77",
          icon: "💡",
          iconAlignment: "left",
        };
      }
    };

    const currFields: Field[] = fields || section?.fields;

    const specificProps = await getSpecificFieldProps();

    const newField: Field = {
      type: field.type,
      label: field.type === "VIDEO_EMBED" ? "" : field.label,
      status: "VISIBLE",
      hint: "",
      order: currFields?.length + 1,
      name: `${field.type}-${currFields?.length + 1}`,
      sectionId: section?.uuid,
      required: field.required || true,
      options: [],
      ...specificProps,
      ...values,
    };

    const newFields: Array<Field> = [...currFields, newField];

    const updatedDraft = {
      ...draft,
      content: sections.map((sec) => {
        if (sec.uuid === section?.uuid) {
          return {
            ...section,
            fields: newFields,
          };
        }

        return sec;
      }),
    };

    handleHasChanges();
    setFields(newFields);
    submitUpdateTemplate(updatedDraft);
  };

  const editField = (editedField: Field) => {
    const matchingField = fields?.find((f) => f.uuid === editedField.uuid);
    let filteredOptions = editedField.options;
    if (filteredOptions && filteredOptions.length > 2) {
      filteredOptions = editedField.options.filter(
        (option) => option.label.trim() !== "" && option.value.trim() !== ""
      );
    }
    if (filteredOptions && filteredOptions.length < 2) {
      filteredOptions.push({ label: "", value: "" });
      filteredOptions.length === 1 &&
        filteredOptions.push({ label: "", value: "" });
    }

    const updatedField = filteredOptions
      ? { ...matchingField, ...editedField, options: filteredOptions }
      : { ...matchingField, ...editedField };

    const updatedFields = fields?.map((f) => {
      if (f.name === updatedField.name) {
        return {
          ...f,
          ...updatedField,
        };
      }

      return f;
    });

    if (
      editedField.type === "IMAGE" ||
      editedField.type === "FILE_ATTACHMENT" ||
      editedField.type === "VIDEO_EMBED"
    ) {
      const fieldIndex = fields.findIndex(
        (field) => field.uuid === editedField.uuid
      );
      const fieldName = `${editedField.type}${fieldIndex}`;

      setValidationErrors({ ...validationErrors, [fieldName]: "" });
    }

    const updatedDraft = {
      ...draft,
      content: sections.map((sec) => {
        if (sec.uuid === updatedField.sectionId) {
          return {
            ...section,
            fields: updatedFields,
          };
        }

        return sec;
      }),
    };

    handleHasChanges();
    setFields(updatedFields);
    submitUpdateTemplate(updatedDraft);
  };

  const deleteField = (field: Field) => {
    const updatedFields = fields?.filter((f) => {
      return f.name !== field?.name;
    });

    updatedFields.forEach((field, index) => {
      if (field) {
        field.order = index + 1;
      }
    });

    const updatedDraft = {
      ...draft,
      content: sections.map((sec) => {
        if (sec.uuid === field.sectionId) {
          return {
            ...section,
            fields: updatedFields,
          };
        }

        return sec;
      }),
    };

    handleHasChanges();
    setFields(updatedFields);
    submitUpdateTemplate(updatedDraft);
  };

  const updateFieldOrder = (dragIndex: number, hoverIndex: number) => {
    const updatedFields = [...fields];
    const draggedField = updatedFields[dragIndex];

    updatedFields.splice(dragIndex, 1);
    updatedFields.splice(hoverIndex, 0, draggedField);

    updatedFields.forEach((field, index) => {
      if (field) {
        field.order = index + 1;
      }
    });

    const updatedDraft = {
      ...draft,
      content: sections.map((sec) => {
        if (sec.uuid === section?.uuid) {
          return {
            ...section,
            fields: updatedFields,
          };
        }

        return sec;
      }),
    };

    handleHasChanges();
    setFields(updatedFields);
    submitUpdateTemplate(updatedDraft);
  };

  const handlePublishOrUpdateTemplate = async ({
    saveAsNew,
    isAutoSave,
  }: {
    saveAsNew: boolean;
    isAutoSave: boolean;
  }) => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      return;
    }

    setIsSaveLoading(true);

    if (draft?.assignedCount === 0) {
      if (draft?.status === "PUBLISHED" || isAutoSave) {
        submitUpdateTemplate(draft, false);
      } else {
        submitUpdateTemplate(
          {
            ...draft,
            status: "PUBLISHED",
          },
          true
        );
      }
      return;
    }

    if (saveAsNew) {
      const slug = await slugGenerator({ string: "cstmasngmnt" });
      const draftUUID = uuid();
      const newTemplate = {
        ...draft,
        uuid: draftUUID,
        slug: slug,
        title: `${draft?.title} (Copy) `,
      };
      submitCreateTemplate(newTemplate);
    } else {
      if (draft?.status === "PUBLISHED") {
        submitUpdateTemplate(draft, false);
      } else {
        submitUpdateTemplate(
          {
            ...draft,
            status: "PUBLISHED",
          },
          true
        );
      }
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful || isUpdateSuccessful) {
      setIsSaveLoading(false);
    }
  }, [isSubmitSuccessful, isUpdateSuccessful]);

  const [showSettingsForm, setShowSettingsForm] = useState(true);

  const defaultValues = {
    currentSection: section,
    currentFields: fields,
    handleSetSection,
    draft,
    category: draft.category,
    handleSaveDraft,
    handleDeleteDraft,
    handleSetDraft,
    addSection,
    editSection,
    deleteSection,
    addField,
    editField,
    deleteField,
    builderDrafts,
    handleUpdateDraftSettings,
    hasChanges,
    handleHasChanges,
    isSaveLoading,
    handlePublishOrUpdateTemplate,
    showSettingsForm,
    setShowSettingsForm,
    updateFieldOrder,
    moodScales,
    validationErrors,
  };

  return {
    defaultValues,
  };
};

const AssignmentBuilderContext = React.createContext<
  ReturnType<typeof useAssignmentBuilderContext>["defaultValues"]
>({} as any);

export { AssignmentBuilderContext, useAssignmentBuilderContext };
