import React, { useEffect, useState } from "react";
import { DataTable, AssignmentIcon, SidePanel } from "components";
import { useViewport, useCurrentUser, useGetSubmissions } from "utils/hooks";
import SubmissionsDataCards from "../SubmissionsDataCards";
import { toAmericanDateFromUnix, toTimeFromUnix } from "utils/helpers";
import SingleSubmission from "../SingleSubmission";
import { useNavigate } from "react-router-dom";
import SubmissionsTablePatientEmptyState from "../SubmissionsTablePatientEmptyState";
import { useTranslation } from "react-i18next";
import { AssignmentSubmission } from "types";

interface SubmissionsTableProps {
  data: any;
  submissionDataColumns: any;
  slug?: string;
  defaultBaseUrl?: string;
  sluggable?: boolean;
  currentPatient?: string;
  groupId?: string;
}

const SubmissionsTable = ({
  data,
  submissionDataColumns,
  slug,
  defaultBaseUrl,
  sluggable = true,
  currentPatient,
  groupId,
}: SubmissionsTableProps) => {
  const { t } = useTranslation(["common"]);
  const { isDesktop } = useViewport();
  const { isTherapist } = useCurrentUser();
  const [showSubmission, setShowSubmission] = useState(false);
  const [currentSubmission, setCurrentSubmission] =
    useState<AssignmentSubmission | null>(null);
  const submissionId = currentSubmission?.slug || slug;
  const { submission: patientSubmission } = useGetSubmissions({
    slug: submissionId,
  });
  const { groupSubmission } = useGetSubmissions({ slug: submissionId });

  const submission = groupId ? groupSubmission : patientSubmission;

  const { isPatient } = useCurrentUser();
  const history = useNavigate();
  const baseUrl =
    defaultBaseUrl || isPatient ? "your-submissions" : "dashboard";

  const rowProps = (row: any, rowRef: any) => {
    if (rowRef) {
      rowRef.onmouseover = () => {
        setCurrentSubmission(row.original);
        setShowSubmission(false);
      };
      rowRef.onclick = () => {
        if (sluggable) {
          history(`/${baseUrl}/${submission?.slug}`);
        }
        setCurrentSubmission(row.original);
        setShowSubmission(true);
      };
    }
  };

  const TitleAssignmentIcon = () => {
    if (submission) {
      return (
        <div className="flex">
          <AssignmentIcon
            style={submission?.assignmentLabel}
            variant="small"
            color={submission?.color}
            icon={submission?.icon?.src}
          />
          {submission?.assignmentLabel}
        </div>
      );
    }
    return null;
  };

  const handleCloseSubmissionSidePanel = () => {
    setCurrentSubmission(null);
    if (sluggable) {
      history(`/${baseUrl}`);
    }
  };

  useEffect(() => {
    if (slug) {
      setCurrentSubmission(submission);
      setShowSubmission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWideView = !isPatient && submission?.isWideData;

  return (
    <>
      {isDesktop ? (
        <DataTable
          data={data}
          columns={submissionDataColumns}
          dataType="submission"
          id="submissions-table"
          rowProps={rowProps}
          emptyStateComponent={<div />}
        />
      ) : (
        <SubmissionsDataCards
          submissions={data}
          onSetSubmission={(sub: AssignmentSubmission) =>
            setCurrentSubmission(sub)
          }
          onShowSubmission={() => setShowSubmission(true)}
        />
      )}
      {!data?.length && (
        <SubmissionsTablePatientEmptyState
          patientId={currentPatient}
          isTherapist={isTherapist}
        />
      )}
      <SidePanel
        title={
          <div className="flex">
            <TitleAssignmentIcon />
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            {toAmericanDateFromUnix(submission?.createdAt)}{" "}
            {t("common_labels.at")} {toTimeFromUnix(submission?.createdAt)}
          </div>
        }
        isVisible={submission && showSubmission ? true : false}
        onClose={handleCloseSubmissionSidePanel}
        width={isWideView ? "800" : "400"}
      >
        <SingleSubmission submission={submission} groupId={groupId} />
      </SidePanel>
    </>
  );
};

export default SubmissionsTable;
