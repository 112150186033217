import { PURGE } from "redux-persist";
import { Collection } from "types";

type Props = {
  type: string;
  payload: any;
};

const initialState = {
  collections: [] as Array<Collection> | [],
  patientCollections: [],
};

export default (state = initialState, { type, payload }: Props) => {
  switch (type) {
    case "CREATE_COLLECTION":
      return {
        ...state,
        collections: [payload, ...state.collections],
      };
    case "FETCH_COLLECTIONS":
      return {
        ...state,
        collections: [...payload],
      };
    case "FETCH_PATIENT_COLLECTIONS":
      return {
        ...state,
        patientCollections: [...payload],
      };
    case "DELETE_COLLECTION":
      const filteredCollections = state.collections.filter(
        (c: Collection) => c.uuid !== payload
      );
      return {
        ...state,
        collections: [...filteredCollections],
      };
    case "UPDATE_COLLECTION":
      return {
        ...state,
        collections: state.collections.map((collection: Collection) =>
          collection.uuid === payload.uuid ? { ...payload } : collection
        ),
      };
    case "UPDATE_ASSIGNED_COLLECTIONS":
      const collectionsToUpdate = [...state.collections];
      payload.map((item: Collection) => {
        const indexToUpdate = state.collections.findIndex(
          (collection) => collection.uuid === item.uuid
        );
        if (indexToUpdate >= 0) {
          collectionsToUpdate.splice(indexToUpdate, 1, item);
        }
      });
      return {
        ...state,
        collections: collectionsToUpdate,
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
