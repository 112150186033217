import { PURGE } from "redux-persist";
import { Practice } from "types";

const initialState = {
  practices: [],
};

interface PracticeReducerType {
  type: string;
  payload: any;
}

export default (
  state = initialState,
  { type, payload }: PracticeReducerType
) => {
  switch (type) {
    case "FETCH_PRACTICES":
      return {
        ...state,
        practices: [...payload],
      };
    case "CREATE_PRACTICE":
      return {
        ...state,
        practices: [...state.practices, payload],
      };
    case "UPDATE_PRACTICE":
      return {
        ...state,
        practices: state.practices.map((practice: Practice) =>
          practice.uuid === payload.uuid
            ? {
                ...practice,
                ...payload,
              }
            : practice
        ),
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
