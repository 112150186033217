import React from "react";
import { LibraryResource, User } from "types";
import Resource from "pages/AppPages/Library/components/Resource";

type Props = {
  links: Array<LibraryResource> | [];
  selectedResources: Array<LibraryResource> | [];
  handleSelectResource: any;
  user: User | null;
};

const LinksForSelect = ({
  links,
  selectedResources,
  handleSelectResource,
  user,
}: Props) => {
  return (
    <>
      {links.map((resource) => {
        const selected = selectedResources.find(
          (item: LibraryResource) => item.uuid === resource.uuid
        );
        return (
          <div
            key={resource.uuid}
            className={`bottom-modal_resource ${
              selected ? "bottom-modal_selected" : ""
            }`}
            role="button"
            tabIndex={0}
            onClickCapture={(e) => handleSelectResource({ resource, e })}
          >
            <Resource
              resource={resource}
              selected={selected}
              isOwner={resource.addedBy === user?.id}
            />
          </div>
        );
      })}
    </>
  );
};

export default LinksForSelect;
