import { useDispatch } from "react-redux";
import {
  fetchPublicLibraryAction,
  fetchTherapistLibraryAction,
  fetchPatientLibraryAction,
  fetchPublicPatientLibraryAction,
  fetchGroupLibraryAction,
} from "store/actions/library";
import { toast } from "react-toastify";
import { useLogout } from "utils/hooks";

const useFetchLibrary = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();

  const fetchPublicLibrary = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getAllLibraryResources {
                resourceUrl
                title
                description
                imageUrl
                uuid
                updatedAt
                sharedWith {
                  value
                  label
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 05AIMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchPublicLibraryAction(resData.data.getAllLibraryResources)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchTherapistLibrary = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getLibraryResourcesByTherapistId {
                resourceUrl
                title
                description
                imageUrl
                uuid
                addedBy
                updatedAt
                sharedWith {
                  label
                  value
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 05BIMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchTherapistLibraryAction(
                resData.data.getLibraryResourcesByTherapistId
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchPatientLibrary = () => {
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getLibraryResourcesByPatientId {
                resourceUrl
                title
                description
                imageUrl
                uuid
                addedBy
                updatedAt
                groupId
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 05CIMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchPatientLibraryAction(
                resData.data.getLibraryResourcesByPatientId
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchPublicPatientLibrary = (patientId) => {
    if (!patientId) return null;

    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            mutation {
              getPublicLibraryResourcesByPatientId(patientId: "${patientId}") {
                resourceUrl
                title
                description
                imageUrl
                uuid
                addedBy
                updatedAt
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 05DIMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchPublicPatientLibraryAction(
                resData.data.getPublicLibraryResourcesByPatientId
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchGroupLibrary = (groupId) => {
    if (!groupId) {
      return;
    }
    return new Promise(() => {
      fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
            query {
              getLibraryResourcesByGroupId(groupId: "${
                groupId ? groupId : null
              }") {
                resourceUrl
                title
                description
                imageUrl
                uuid
                addedBy
                updatedAt
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (res) => {
          if (res.status !== 200 && res.status !== 201) {
            const error = await res.json();
            if (error.errors[0].message.includes("BRKN_6001")) {
              toast.warn(`For security purposes please log in again.`);
              logOut();
            } else {
              toast.error(`Hmm, something went wrong.`);
              throw new Error("Failed!");
            }
          }
          return res.json();
        })
        .then((resData) => {
          if (resData.errors) {
            toast.error(
              `Hmm, something went wrong (Code: 05EIMN). ${resData.errors[0].message}`
            );
          } else {
            return dispatch(
              fetchGroupLibraryAction(resData.data.getLibraryResourcesByGroupId)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return {
    fetchPublicLibrary,
    fetchTherapistLibrary,
    fetchPatientLibrary,
    fetchPublicPatientLibrary,
    fetchGroupLibrary,
  };
};

export default useFetchLibrary;
