import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { setAuthenticatedUser } from "store/actions/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useFetchAssignments,
  useFetchMoodScales,
  useFetchPatients,
  useFetchSubmissions,
  useFetchTherapistProfile,
  useFetchTherapists,
  useQueryParams,
  useSendCode,
  useFetchGroups,
  useFetchPractices,
} from "utils/hooks";

const useLogin = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { fetchAllPatients } = useFetchPatients();
  const { fetchAllTherapists } = useFetchTherapists();
  const { fetchAllSubmissions } = useFetchSubmissions();
  const { fetchTherapistProfile } = useFetchTherapistProfile();
  const { fetchAllAssignments } = useFetchAssignments();
  const { fetchAllMoodScales } = useFetchMoodScales();
  const { sendCode } = useSendCode();
  const { fetchGroups } = useFetchGroups();
  const { fetchPractices } = useFetchPractices();

  const { hasUrlParams } = useQueryParams();
  const nextUrl = hasUrlParams("next");
  const qrTokenParam = hasUrlParams("qrToken");

  const submitLogin = async (args: {
    email: string;
    password: string;
    fromInvite?: string | null;
    inviteTherapistToken?: string | null;
    qrTokenParam?: string | null;
  }) => {
    if (!args) {
      return null;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          query: `
          query {
            login(
              email: "${args.email}",
              password: "${args.password}",
            ) {
              userId
              firstName
              lastName
              email
              expiresIn
              type
              verified
              emailNotifications 
              pushNotifications
              digestSubscription
              isTherapistProfileComplete
              lastLogin
              isSubscribed
              createdAt
              plan {
                name
                patientLimit
                assignmentLimit
                customAssignmentLimit
                customResourceLimit
                fileLimit
                groupLimit
              }
              defaultLanguage
              is2FAEnabled
              slug
              defaultReminder
              notificationTimes
              daysOfWeek
              notificationMessage
              color
              icon
              alias
              activeOrganizationId
              organizations {
                organizationId
                name
              }
            }
          }
        `,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          organizationId: "NA",
          permissionKey: "login",
        },
      });

      const resData = await response.json();

      if (resData.errors && resData.errors[0].message === "GNRC_6016") {
        toast.error(
          `Please check your email to accept the invitation your therapist sent you.`
        );
        throw new Error("Failed!");
      }

      if (response.status !== 200 && response.status !== 201) {
        toast.error(`Hmm, something went wrong.`);
        throw new Error("Failed!");
      }

      const { login } = resData.data;

      if (login.is2FAEnabled) {
        await sendCode(login.email);
        let path = "/login/2fa";
        if (nextUrl) {
          path += `?next=${nextUrl}`;
        }
        if (qrTokenParam) {
          path += `?qrToken=${qrTokenParam}`;
        }
        return history(path, { state: login });
      } else {
        ReactGA.event("login_success", {
          user_id: login.userId,
          user_type: login.type,
          user_verified: login.verified,
        });
        const res = dispatch(setAuthenticatedUser(login));

        // fetching some data on login
        if (res.payload.type === "therapist") {
          fetchAllPatients();
          fetchAllSubmissions();
          fetchTherapistProfile();
          fetchAllAssignments();
          fetchAllMoodScales();
          fetchGroups();
          fetchPractices();
        }

        if (res.payload.type === "patient") {
          fetchAllTherapists();
          fetchAllAssignments();
          fetchGroups();
        }

        if (login.verified && login.verifed !== "false") {
          toast("Great to see you!");
        }

        if (!login.verified || login.verified === "false") {
          const queryParams = new URLSearchParams();
          if (qrTokenParam) queryParams.append("qrToken", qrTokenParam);
          if (args.fromInvite)
            queryParams.append("fromInvite", args.fromInvite);
          if (args.inviteTherapistToken)
            queryParams.append(
              "inviteTherapistToken",
              args.inviteTherapistToken
            );

          return history(`/verification?${queryParams.toString()}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    submitLogin,
  };
};

export default useLogin;
