import { Collection, LibraryResource, Group } from "types";

// Filters all therapist link resources excluding resources in collections and already assigned to the group resources
export const filterTherapistResources = (
  therapistResources: LibraryResource[],
  groupResources: LibraryResource[],
  collections: Collection[]
) => {
  const collectionResourcesIds = collections.reduce((acc, collection) => {
    collection.collectionResources.map((r) => acc.push(r.uuid));
    return acc;
  }, [] as Array<string>);

  return therapistResources.filter(
    (resource) =>
      !groupResources.find((r) => r.uuid === resource.uuid) &&
      !collectionResourcesIds.includes(resource.uuid)
  );
};

export const createTitleForModal = (group: Group, type: any) => {
  if (!group.name) {
    return `Add ${type}`;
  } else {
    return `Add ${type} for ${group.name}`;
  }
};
